<template>
	<div>
		<p>{{message}}</p>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				message: "Hello World"
			}
		}
	}
</script>